import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { concatMap, debounceTime, map, take } from 'rxjs/operators';
import { InactiveUserService } from 'src/app/core/components/inactive-user/inactive-user.service';
import { environment } from 'src/environments/environment';
import { BaseFormComponent } from '../../../../core/abstractions/base-form.component';
import { CustomValidators } from '../../../../core/util/custom-validators';
import { AuthFirebaseService } from '../auth-firebase.service';

import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { InfoDialogComponent } from 'src/app/core/components/info-dialog/info-dialog.component';
import { LoggedInUser } from 'src/app/shared/models/user.model';
import { CookieDialogComponent } from '../../../../shared/components/cookie-dialog/cookie-dialog.component';
import { CookieTypesEnum } from '../../../../shared/enums/cookie-types.enum';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent
    extends BaseFormComponent
    implements OnInit, OnDestroy
{
    showPassword = false;
    showRecaptcha = false;
    isProductionMode;
    subscriptions = new Subscription();

    constructor(
        private auth: AuthFirebaseService,
        private titleService: Title,
        private inactiveUserService: InactiveUserService,
        private dialog: MatDialog,
        private activatedGuard: ActivatedRoute
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.isProductionMode = environment.production;
        this.titleService.setTitle('Finstein - Portal');
        if (this.activatedGuard.snapshot.queryParams.disableRedirect) {
            this.auth.signOut().subscribe();
            if (this.activatedGuard.snapshot.queryParams.multipleSession) {
                this.dialog.open(InfoDialogComponent, {
                    panelClass: 'curved-modal',
                    data: {
                        title: 'new-login-detected.title',
                        text: 'new-login-detected.text',
                        doneBtnTitle: 'system.close',
                    },
                });
            }
        } else {
            this.spinner.show();
            this.userService.loadCurrentUser()
            .pipe(
                concatMap(() => this.userService.isLogged()),
                take(1)
            ).subscribe((isLogged) => {
                if (isLogged) {
                    this.navigate(['/']);
                } else {
                    this.inactiveUserService.stopInactiveUserSchedule();
                }
                this.spinner.hide();
            });
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        super.ngOnDestroy();
    }

    getFormControls() {
        // Reset captcha on change password
        const pwControl = new UntypedFormControl(null, [
            Validators.required,
            CustomValidators.noWhitespaceValidator,
        ]);
        pwControl.valueChanges.pipe(debounceTime(500)).subscribe(() => {
            this.form.controls.recaptchaReactive.reset();
        });
        return {
            email: new UntypedFormControl(null, [
                Validators.required,
                CustomValidators.noWhitespaceValidator,
                CustomValidators.email,
            ]),
            password: pwControl,
            recaptchaReactive: new UntypedFormControl(
                { value: null, disabled: true },
                Validators.required
            ),
        };
    }

    onSubmit() {
        if (this.form.valid) {
            this.spinner.show();
            const request = this.form.value;
            request.email = request.email.toLowerCase();
            this.subscriptions.add(
                this.auth
                    .signIn(request)
                    .pipe(take(1))
                    .subscribe({
                        next: (loggedUser: LoggedInUser) => {
                            if (loggedUser?.finsteinUser) {
                                if (this.userService.isFinsteinHelper()) {
                                    this.navigate([
                                        '/administration/finstein-helper',
                                    ]);
                                } else {
                                    this.navigate(['/administration']);
                                }
                            } else {
                                this.navigate(['/']);
                            }
                            this.inactiveUserService.startInactiveUserSchedule();
                            this.spinner.hide();
                        },
                        error: (error) => {
                            this.spinner.hide();
                            if (error.code === 'auth/too-many-requests') {
                                this.notification.error(error.message);
                            } else {
                                this.notification.error(
                                    'messages.invalid-login'
                                );
                            }
                            this.fireLogginService.sendAlertLog(
                                `Error when trying to login into Portal. Email: ${request.email}, details: ${error.message}`
                            );
                            if (this.isProductionMode) {
                                this.showRecaptcha = true;
                                this.form.controls.recaptchaReactive.enable();
                                this.form.controls.recaptchaReactive.reset();
                            }
                        },
                    })
            );
        } else {
            this.form.markAllAsTouched();
        }
    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }

    openCookieDialog() {
        this.dialog.open(CookieDialogComponent, {
            width: '650px',
            height: 'auto',
            maxHeight: '95vh',
            data: {
                cookieTypes: [
                    CookieTypesEnum.NECESSARY,
                    CookieTypesEnum.PREFERENCES,
                ],
            },
        });
    }
}
